
import { funk } from '@theme-ui/presets'

export default {
  ...funk,
  colors: {
    ...funk.colors,
    // backgroundImage: 'linear-gradient(7deg, rgba(2,0,36,1) 50%, rgba(160,32,240,1) 93%)'
    // background: 'black'
    // background: 'linear-gradient(7deg, rgba(2,0,36,1) 50%, rgba(160,32,240,1) 93%)',
  },
  styles: {
    ...funk.styles,
  },
  sizes: {
    sidebar: 256,
  },
}





