import { useStaticQuery, graphql } from "gatsby"

export const useSiteIcon = () => {
  const { file } = useStaticQuery(
    graphql`
      query MyQuery {
        file(relativePath: { eq: "icon.png" }) {
          childImageSharp {
            fluid {
              srcSet
              src
            }
          }
        }
      }
    `
  )
  return file.childImageSharp.fluid
}
