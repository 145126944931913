import { Link } from "gatsby"
import { NavLink, NavLinkProps } from "theme-ui"

interface ExtendedNavLinkProps extends NavLinkProps {
  to: string
  as?: typeof Link
  replace?: Boolean
}
export const ExtendedNavLink = (props: ExtendedNavLinkProps) => {
  return (
    <NavLink {...props} as={Link}></NavLink>
  )
}
