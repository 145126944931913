/** @jsxImportSource theme-ui */

import { GatsbyBrowser, HeadFC, Link } from "gatsby"
import Header from './src/components/header'
import { Grid } from "theme-ui"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }: any) => {
  return (

    <Grid
      columns={'1fr'}
      sx={{
        height: '100vh',
        backgroundColor: '#26013d;',
        backgroundImage: 'linear-gradient(162deg, #26013d 0%, #12001f 100%);',
        gridTemplateRows: 'auto 1fr auto',
        gridTemplateAreas:
          "'header' 'main' 'footer'"
      }}
    >

      <Header sx={{
        gridArea: 'header'
      }} />
      <main sx={{ overflow: 'auto', gridArea: 'main', position: 'relative', padding: '1em', }}>
        {element}
      </main >
      <footer
        sx={{
          gridArea: 'footer',
          textAlign: 'right',
          padding: '0 1em 1em 0',
          color: "white",
          // background: 'orange'
        }}
      >
        {(new Date).getFullYear()}
      </footer>
    </Grid>

  )
}
