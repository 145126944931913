
import { useState } from "react"
import { Avatar, Box, Card, Flex, Heading, MenuButton, SxProp } from "theme-ui"
import { useSiteIcon } from "../hooks/use-site-icon"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { ExtendedNavLink } from "./ExtendedNavLink"


const Header = () => {
  const { title } = useSiteMetadata()
  const { src: icon } = useSiteIcon()
  const [isMenuVisible, setMenuVisibility] = useState(false)

  const handleClick = _ => {
    setMenuVisibility((current: Boolean) => !current)
  }
  return (

    <Box p={4} bg='#DFB7F9'>
      <Flex
        sx={{
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}>
        <Card
          sx={{ display: 'flex', alignItems: 'center' }}
        >

          <Avatar src={icon} sx={{ backgroundColor: 'white' }} />
          <Box sx={{ padding: '0 1em' }}>
            <Heading >{title}</Heading>
            <Heading
              sx={{
                display: 'none',
                '@media screen and (min-width: 40em)': {
                  display: 'initial',
                },
              }}
              as='h3'>Hypothetical, Political, Lyrical Miracle Whip</Heading>
          </Box>
        </Card>


        <MenuButton aria-label="Toggle Menu"
          onClick={handleClick}
          ml="auto"
          sx={{
            cursor: 'pointer',
            // flexBasis: '100%',
            display: 'block',
            // marginRight: 'auto',
            '@media screen and (min-width: 40em)': {
              display: 'none',
            },

          }}
        />
        <Card as="nav" ml="auto"
          onClick={handleClick}
          sx={{
            display: () => isMenuVisible ? 'flex' : 'none',
            textAlign: "center",
            flexBasis: '100%',
            flexDirection: 'column',
            '@media screen and (min-width: 40em)': {
              display: 'flex',
              flexDirection: 'row',
              flexBasis: 'auto'
            },

          }}
        >

          <ExtendedNavLink
            sx={{
              color: 'inherit',
              '&.active': {
                color: 'primary',
              },
            }}
            to="/" p={2}>
            Home
          </ExtendedNavLink>
          <ExtendedNavLink
            sx={{
            }}
            to="/blog" p={2}>
            Blog
          </ExtendedNavLink>
          <ExtendedNavLink
            sx={{
            }}
            to="/photos" p={2}>
            Photos
          </ExtendedNavLink>
        </Card>
      </Flex>
    </Box>


  )
}

export default Header
